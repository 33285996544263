<template>
  <div class="y-footer">
    <!-- info -->
    <div id="y-art-footer" v-show="showFootImage">
      <div>
        <p>{{ $t(artworks + "bg[0]") }}</p>
        <p>{{ $t(artworks + "bg[1]") }}</p>
        <p>{{ $t(artworks + "bg[2]") }}</p>
      </div>
    </div>
    <div class="y-footer-link" v-show="showFootMenu">
      <el-row :gutter="20">
        <el-col class="y-footer-link-col">
          <!-- <img class="y-logo" src="@/assets/img/logo.png"/> -->
          <div class="y-title">YUGINI</div>

        </el-col>
        <el-col class="y-footer-link-col">
          <div class="y-menu-entry" @click="hrefTo('index')">{{ $t(header + "home") }}</div>
          <div class="y-menu-entry" @click="hrefTo('news')">{{ $t(header + "news") }}</div>
          <div class="y-menu-entry" @click="hrefTo('artworks')">{{ $t(header + "artworks") }}</div>
          <div class="y-menu-entry" @click="hrefTo('exhibitions')">{{ $t(header + "exhibitions") }}</div>
          <div class="y-menu-entry" @click="hrefTo('contact')">{{ $t(header + "contact") }}</div>
        </el-col>
        <el-col class="y-footer-link-col">
<!--          <div class="y-menu-entry" @click="hrefTo('index')">{{ $t(header + "follow") }}</div>-->
<!--          <img class="y-ins" src="@/assets/img/icon/instagram.png"/>-->
        </el-col>
      </el-row>
    </div>
    <div class="y-pub-footer">
      <div>© 2024 YUGINI</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* MODULES && COMPONENTS */
import {watch, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
const router = useRouter()
const route = useRoute()
import {useI18n} from "vue-i18n";
const i18n = useI18n()
import { dateTransfer } from "@/assets/js/common";
/* DATA */
const showFootMenu = ref<boolean>(true)
const showFootImage = ref<boolean>(true)
const showFootSwiper = ref<boolean>(true)
const header = ref<string>('header.')
const artworks = ref<string>('artworks.')
/* DOM INIT */
/* FUNCTIONS */
const hrefTo = (index: string, params = {}) => {
  router.push({
    name: index,
    params: params
  })
}
/* WATCH && COMPUTE */
watch(() => route.path, (val) => {
  showFootMenu.value = !['/index', '/artwork'].includes(val)
  showFootImage.value = !['/index', '/artwork', '/news_detail', '/exhibition_detail'].includes(val)
  showFootSwiper.value = !['/index', '/artwork'].includes(val)
}, {immediate: true})
/* DESTORY */
</script>

<style lang="scss" scoped>
.y-footer{
  width: 100vw;
  //position: fixed;
  //z-index: 100;
  //bottom: 0;
  background: white;
  >.y-pub-footer{
    max-width: 1530px;
    padding: 0 75px;
    height: 120px;
    margin: 0 auto;
    text-align: left;
    div{
      border-top: 1px solid black;
      font-size: 20px;
      line-height: 34px;
      padding-top: 20px;
    }
  }
  @media (max-width: 799px) {
    >.y-pub-footer{
      padding: 0 30px !important;
    }
  }
  .y-footer-link{
    max-width: 1530px;
    padding: 0 75px;
    text-align: left;
    margin: 0 auto;
    :deep(.el-row){
      margin-top: 60px;
      margin-bottom: 160px;
    }
    .y-footer-link-col{
      width: 25%;
      max-width: 25%;
      .y-title{
        float: left;
        padding-left: 5px;
        // padding-top: 60px;
        height: 50px;
        font-size: 35px;
        font-family:  Ali-Bold;
      }
      .y-menu-entry{
        color: #A3A3A3;
        font-size: 24px;
        line-height: 41px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .y-ins{
        width: 48px;
        height: 48px;
      }
    }
  }
  @media (max-width: 799px) {
    .y-footer-link{
      padding: 0 30px !important;
      :deep(.el-row){
        margin-top: 50px !important;
        margin-bottom: 50px !important;
      }
      .y-footer-link-col {
        width: 50% !important;
        max-width: 50% !important;
      }
    }
  }
  //footer-image
  #y-art-footer {
    width: 100vw;
    height: 38.125vw;
    min-height: 400px;
    background: url('@/assets/img/arts-bg.png') no-repeat;
    background-size: cover;

    > div {
      width: 40vw;
      margin-left: 60vw;
      height: 38.125vw;
      line-height: 38.125vw;
      padding-top: calc(19.06vw - 60px);

      > p {
        text-align: left;
        font-size: 20px;
        color: white;
        line-height: 28px;
        margin: 0;
      }
    }

    @media (max-width: 799px) {
      > div {
        width: 80vw;
        margin-left: 10vw;
        padding-top: 100px;
      }
    }
  }
}
</style>
