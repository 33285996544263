<template>
  <div class="y-header">
    <div>
      <div>
        <!-- <img class="y-logo" src="@/assets/img/logo.png"/> -->
        <div class="y-title">YUGINI</div>

        <img
          class="y-menu-open"
          src="@/assets/img/icon/menu.png"
          @click="handleMenuOpen"
        />
       <div class="y-edit" v-if="showEdit" @click="startEditing">
        {{ editorFlag ? $t(header + "closeEditor") : $t(header + "edit") }}
      </div>
      </div>
    </div>
  </div>
  <div class="y-menu-container" v-show="showMenu">
    <img
      class="y-menu-close"
      src="@/assets/img/icon/close.png"
      @click="handleMenuClose"
    />
    <div class="y-menu">

      <div class="y-menu-entry" @click="hrefTo('index')">
        {{ $t(header + "home") }}
      </div>
      <div class="y-menu-entry" @click="hrefTo('news')">
        {{ $t(header + "news") }}
      </div>

      <div class="y-menu-entry" @click="hrefTo('artworks')">
        {{ $t(header + "artworks") }}
      </div>
      <div class="y-menu-entry" @click="hrefTo('exhibitions')">
        {{ $t(header + "exhibitions") }}
      </div>
      <div class="y-menu-entry" @click="hrefTo('contact')">
        {{ $t(header + "contact") }}
      </div>
      <div class="y-menu-entry" @click="hrefTo('login')" v-if="showLogin">
        {{ $t(header + "login") }}
      </div>
    </div>
    <div class="y-lang">
      <div class="y-lang-item" @click="changeLocale('en')">EN</div>
      <div class="y-lang-item" @click="changeLocale('fr')">FR</div>
      <div class="y-lang-item" @click="changeLocale('tc')">繁體</div>
      <div class="y-lang-item" @click="changeLocale('zh')">简体</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* MODULES && COMPONENTS */
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
const i18n = useI18n();
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const router = useRouter();
const route = useRoute();

/* DATA */
const store = useStore();
const showEdit = ref(false);
const showMenu = ref<boolean>(false);
const header = ref<string>("header.");
const editorFlag = ref(store.state.editorState);
const showLogin=ref(false)
/* DOM INIT */
/* FUNCTIONS */
onMounted(() => {});
const handleMenuOpen = () => {
  showMenu.value = true;
};
const handleMenuClose = () => {
  showMenu.value = false;
};
const changeLocale = (value: string) => {
  i18n.locale.value = value;
  localStorage.setItem("locale", value);
};
const hrefTo = (index: string, params = {}) => {
  handleMenuClose();
  router.push({
    name: index,
    params: params,
  });
};

const startEditing = () => {
  store.commit("updateEditor", !editorFlag.value);
  editorFlag.value = store.state.editorState;
};
/* WATCH && COMPUTE */
const lang = ref<string>(localStorage.getItem("locale") || "en");
watch(
  () => i18n.locale.value,
  (val) => {
    lang.value = val;
  },
  { immediate: true }
);

watch(
  () => store.state.user_info,
  (val) => {
    if (val.user_id === "1") {
      showEdit.value = true;
    } else {
      showEdit.value = false;
    }
  },
  { immediate: true }
);
watch(
  ()=>route.query.show_login_button,
  (val)=>{
    if (val==="yugini") {
        showLogin.value=true
    }
    
  },
  {immediate:true}
)
/* DESTORY */
</script>

<style lang="scss" scoped>
.y-header {
  height: 183px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);

  div {
    max-width: 1530px;
    padding: 0 75px;
    height: 183px;
    margin: 0 auto;
    div {
      padding: 0;
      border-bottom: 1px solid #000;
      .y-logo {
        float: left;
        padding-left: 15px;
        padding-top: 60px;
        height: 60px;
      }
      .y-title {
        border-bottom: 0px;
        float: left;
        padding-left: 5px;
        padding-top: 60px;
        height: 50px;
        font-size: 35px;
        font-family: Ali-Bold;
      }
      .y-edit {
        cursor: pointer;
        float: right;
        padding-top: 80px;
        padding-right: 70px;
        height: 48px;
        border-bottom: 0px;
      }
      .y-menu-open {
        cursor: pointer;
        float: right;
        height: 48px;
        width: 48px;
        padding-top: 66px;
      }
    }
  }
}
@media (max-width: 799px) {
  .y-header {
    height: 100px !important;
    div {
      height: 100px !important;
      .y-logo {
        padding-top: 30px !important;
      }
      .y-menu-open {
        padding-top: 36px !important;
      }
    }
    > div {
      padding: 0 30px !important;
    }
  }
}
.y-menu-container {
  height: 100vh;
  width: 100vw;
  background: rgba(26, 26, 26, 1);
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  .y-menu {
    width: 100%;
    max-width: 720px;
    margin: calc(50vh - 180px) auto 0;
    text-align: center;
    .y-menu-entry {
      color: rgba(255, 255, 255, 1);
      opacity: 0.5;
      font-size: 26px;
      cursor: pointer;
      margin-bottom: 20px;
      height: 40px;
      line-height: 40px;
      display: block;
      animation: menu-entry-ease-out 0.3s;
    }
    .y-menu-entry:hover {
      opacity: 1;
      animation: menu-entry-ease-in 0.3s;
    }
  }
  .y-lang {
    margin-top: 40px;
    .y-lang-item {
      color: rgba(255, 255, 255, 1);
      opacity: 0.5;
      font-size: 26px;
      cursor: pointer;
      margin: 0 25px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      animation: menu-entry-ease-out 0.3s;
    }
    .y-lang-item:hover {
      opacity: 1;
      animation: menu-entry-ease-in 0.3s;
    }
  }
  .y-menu-close {
    position: fixed;
    z-index: 110;
    right: 71px;
    top: 71px;
    opacity: 0.5;
    cursor: pointer;
    animation: menu-entry-ease-out 0.3s;
  }
  .y-menu-close:hover {
    opacity: 1;
    animation: menu-entry-ease-in 0.3s;
  }
}
@keyframes menu-entry-ease-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-entry-ease-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>
