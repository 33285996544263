export default {
  zh: {
    home: "首页",
    news: "新闻",
    artworks: "作品",
    exhibitions: "展览",
    contact: "关于",
    follow: "关注我",
    login: "登录",
    edit: "启用编辑",
    closeEditor: "关闭编辑",
  },
  tc: {
    home: "首頁",
    news: "新聞",
    artworks: "作品",
    exhibitions: "展覽",
    contact: "關於",
    follow: "關注我",
    login: "登錄",
    edit: "啓用編輯",
    closeEditor: "關閉編輯",
  },
  en: {
    home: "HOME",
    news: "NEWS",
    artworks: "ARTWORKS",
    exhibitions: "EXHIBITIONS",
    contact: "CONTACT",
    follow: "FOLLOW US",
    login: "LOGIN",
    edit: "Enable Editing",
    closeEditor: "Close Editing",
  },
  fr: {
    home: "HOME",
    news: "NEWS",
    artworks: "ARTWORKS",
    exhibitions: "EXHIBITIONS",
    contact: "CONTACT",
    follow: "FOLLOW US",
    login: "SE CONNECTER",
    edit: "Activer l'édition",
    closeEditor: "Fermer Modifier",
  },
};
