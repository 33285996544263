export default {
  zh: {

  },
  en: {

  },
  tc: {

  },
  fr: {

  }
}
