import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createI18n } from "vue-i18n";
import SC from "./assets/js/i18n/locales/zh";
import EN from "./assets/js/i18n/locales/en";
import TC from "./assets/js/i18n/locales/tc";
import FR from "./assets/js/i18n/locales/fr";
import "@/assets/fonts/font.scss"; // 引入字体样式


const app = createApp(App);
const i18n = new (createI18n as any)({
  // 默认语言
  locale: localStorage.getItem('locale') || "zh",
  globalInjection: true,
  legacy: false,
  // 引入语言文件
  messages: {
    zh: SC,
    en: EN,
    tc: TC,
    fr: FR,
  },
});
export default i18n;
app.use(ElementPlus);
app.use(store);
app.use(router);
app.use(i18n);
app.mount("#app");
