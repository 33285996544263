import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import YContainer from '@/layout/YContainer.vue'
import { check_login } from "@/assets/js/api/userServices";
import  store  from "../store/index";

const routes: Array<RouteRecordRaw> = [{
    path: "/",
    redirect: "/index",
    name: "index",
    component: YContainer,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import('@/views/YIndex.vue'),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/artworks",
        name: "artworks",
        component: () => import('@/views/ArtsList.vue'),
        meta: {
          title: "作品",
        },
      },
      {
        path: "/artwork",
        name: "artwork",
        component: () => import('@/views/ArtsDetail.vue'),
        meta: {
          title: "作品详情",
        },
      },
      {
        path: "/news",
        name: "news",
        component: () => import('@/views/NewsList.vue'),
        meta: {
          title: "新闻",
        },
      },
      {
        path: "/news_detail",
        name: "news_detail",
        component: () => import('@/views/NewsDetail.vue'),
        meta: {
          title: "新闻详情",
        },
      },
      {
        path: "/exhibitions",
        name: "exhibitions",
        component: () => import('@/views/Exhibitions.vue'),
        meta: {
          title: "新闻",
        },
      },
      {
        path: "/exhibition_detail",
        name: "exhibition_detail",
        component: () => import('@/views/ExhibitionDetail.vue'),
        meta: {
          title: "新闻",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import('@/views/Contact.vue'),
        meta: {
          title: "联系我",
        },
      },
    ]
  },
  {
    path: "/yugini_admin_login_page",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(function (to, from, next) {
  check_login().then(res=>{
    store.state.user_info = res;
  })
  window.scrollTo(0, 0)
  next()
})
export default router;
