export default {
  zh: {
    SignIn: "请登录",
    login: "登录",
    username: "用户名",
    password: "密码",
    success: "登录成功",
    rule1: "请输入用户名",
    rule2: "请输入密码",
  },
  tc: {
    SignIn: "請登錄",
    login: "登錄",
    username: "用戶名",
    password: "密碼",
    success: "登錄成功",
    rule1: "請輸入用戶名",
    rule2: "請輸入密碼",
  },
  en: {
    SignIn: "Please Sign In",
    login: "LOGIN",
    username: "username",
    password: "password",
    success: "Login succeeded",
    rule1: "Please input Activity username",
    rule2: "Please input Activity password",
  },
  fr: {
    SignIn: "Veuillez vous connecter",
    login: "SE CONNECTER",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    success: "Connexion réussie",
    rule1: "Veuillez entrer un nom d'utilisateur",
    rule2: "Veuillez entrer votre mot de passe",
  },
};
