export default {
  zh: {
    recent: '近期',
    artworks: '作品',
    past: '更早',
    bg: ['Peu importe l\'eau, tout se fond dans l\'océan',
      'Le moment de naissance et de mort est juste un rétablissement',
      'Rien n\'est ni créé ni détruit, seulement restauré.']
  },
  en: {
    recent: 'RECENT',
    past: 'PAST',
    artworks: 'ARTWORKS',
    bg: ['Peu importe l\'eau, tout se fond dans l\'océan',
      'Le moment de naissance et de mort est juste un rétablissement',
      'Rien n\'est ni créé ni détruit, seulement restauré.']
  },
  tc: {
    recent: '近期',
    past: '更早',
    artworks: '作品',
    bg: ['Peu importe l\'eau, tout se fond dans l\'océan',
      'Le moment de naissance et de mort est juste un rétablissement',
      'Rien n\'est ni créé ni détruit, seulement restauré.']
  },
  fr: {
    recent: 'RECENT',
    past: 'PAST',
    artworks: 'ARTWORKS',
    bg: ['Peu importe l\'eau, tout se fond dans l\'océan',
      'Le moment de naissance et de mort est juste un rétablissement',
      'Rien n\'est ni créé ni détruit, seulement restauré.']
  },
}
