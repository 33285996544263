import { httpService } from "@/assets/js/httpServices";
import { LoginVM, NewsListVM } from "@/assets/js/api/userServices.d";

export class Contact {
  info() {
    return httpService.post("/v1/contact/info");
  }
  biography() {
    return httpService.post("/v1/contact/biography_list");
  }
  biography_add(data: any) {
    return httpService.post("/v1/contact/biography_add", data);
  }
  add(data: any) {
    return httpService.post("/v1/contact/add", data);
  }
  del(biography_id: number | string) {
    return httpService.post("/v1/contact/biography_del", {
      biography_id: biography_id,
    });
  }
}

export class Artwork {
  list(is_cur_year: number, page = 0, limit = 20) {
    return httpService.post("/v1/artwork/list", {
      is_cur_year: is_cur_year,
      page: page,
      limit: limit,
    });
  }
  info(artwork_id: number | string) {
    return httpService.post("/v1/artwork/info", {
      artwork_id: artwork_id,
    });
  }
  del(artwork_id: number | string) {
    return httpService.post("/v1/artwork/artwork_del", {
      artwork_id: artwork_id,
    });
  }
  add(data: any) {
    return httpService.post("/v1/artwork/artwork_add", data);
  }
  fabu(artwork_id: number | string) {
    return httpService.post("/v1/artwork/fabu", {
      artwork_id: artwork_id,
    });
  }
}

export class Exhibition {
  list(page = 0, limit = 20) {
    return httpService.post("/v1/exhibitions/list", {
      page: page,
      limit: limit,
    });
  }
  info(exhibitions_id: number | string) {
    return httpService.post("/v1/exhibitions/info", {
      exhibitions_id: exhibitions_id,
    });
  }
  del(exhibitions_id: number | string) {
    return httpService.post("/v1/exhibitions/exhibitions_del", {
      exhibitions_id: exhibitions_id,
    });
  }
  add(data: any) {
    return httpService.post("/v1/exhibitions/exhibitions_add", data);
  }
  fabu(exhibitions_id: number | string) {
    return httpService.post("/v1/exhibitions/fabu", {
      exhibitions_id: exhibitions_id,
    });
  }
}

export class News {
  list(page = 0, limit = 20) {
    return httpService.post("/v1/news/list", {
      page: page,
      limit: limit,
    });
  }
  info(news_id: number | string) {
    return httpService.post("/v1/news/info", {
      news_id: news_id,
    });
  }
  del(news_id: number | string) {
    return httpService.post("/v1/news/news_del", {
      news_id: news_id,
    });
  }
  add(data: any) {
    return httpService.post("/v1/news/news_add", data);
  }
  fabu(news_id: number | string) {
    return httpService.post("/v1/news/fabu", {
      news_id: news_id,
    });
  }
}

export function upload(file: any) {
  return httpService.post("/v1/file/upload", {
    upload: file,
  });
}
export function config() {
  return httpService.post("/v1/home/config");
}
export function login(username: string, password: string) {
  return httpService.post("/v1/home/login", {
    username: username,
    password: password,
  });
}
export function check_login() {
  return httpService.post("/v1/home/check_login");
}
