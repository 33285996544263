export default {
  zh: {
    about: '关于YUGINI',
    mail: `yuginibbb{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: '自传',
    articles: '作品',
    news: '新闻',
    collection: '作品集'
  },
  en: {
    about: 'ABOUT YUGINI',
    mail: `yuginibbb{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: 'BIOGRAPHY',
    articles: 'ARTICLES',
    news: 'NEWS',
    collection: 'COLLECTIONS'
  },
  tc: {
    about: '關於YUGINI',
    mail: `yuginibbb{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: '自傳',
    articles: '作品',
    news: '新聞',
    collection: '作品集'
  },
  fr: {
    about: 'ABOUT YUGINI',
    mail: `yuginibbb{'@'}gmail.com`,
    paragraph1: 'YUGINI est une artiste et designer qui asuivi un cursus de Master d\'Art à l\'université Paris 8. Son travail est lié àla régénération des espaces naturels et à l\'écologie ainsi qu\'à la sciencespirituelle du cycle de vie.',
    paragraph2: 'Elle éveille ainsi le public à la conscience de l\'environnement, ainsiqu\'à l\'importance de l\'esprit dans les processus de perception ou de création.Ses projets pluridisciplinaires associent la résonance visuelle et sonore, lapeinture et la chorégraphie, les performances, installations, vidéo, poésie,design, méditation, acupuncture…YUGINI met en lumière les expériences liées auxénergies.',
    biography: 'BIOGRAPHY',
    articles: 'ARTICLES',
    news: 'NEWS',
    collection: 'COLLECTIONS'
  },
}
