import header from '../langEnum/header'
import index from '../langEnum/index'
import artworks from '../langEnum/artworks'
import contact from '../langEnum/contact'
import login from '../langEnum/login'

const lang = 'zh'
export default {
  header: header[lang],
  index: index[lang],
  artworks: artworks[lang],
  contact: contact[lang],
  login: login[lang],
}
