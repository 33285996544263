import { createStore } from "vuex";

export default createStore({
  state: {
    server: {
      response: {
        data: {
          message: "" as string,
          data: "" as string,
        },
      },
    },
    user_info: {},
    config:{},
    editorState: false, // 编辑模式
  },
  getters: {},
  mutations: {
    updateEditor(state, newValue) {
      state.editorState = newValue;
    },
  },
  actions: {},
  modules: {},
});
